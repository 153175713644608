$(document).ready(() => {
	// ===================================
	// Nav
	// ===================================
	$('#nav--button').click(() => {
		if ($('#nav').hasClass('flex')) {
			// Close menu
			$('#nav--bg').fadeOut();
			// Slide up the element with ID 'nav', and after the animation is complete, remove the 'display' CSS property to reset it to its default value
			$('#nav').slideUp(() => {
				$('#nav').css('display', '');
			});
			$('#nav').toggleClass('hidden flex');
			$('body').css('overflow', 'auto');
		} else {
			// Open menu
			$('#nav--bg').fadeIn();
			$('#nav').slideDown();
			$('#nav').toggleClass('hidden flex');
			$('body').css('overflow', 'hidden');
		}
	});

	// ===================================
	// Back to top button
	// ===================================
	let $backToTopButton = $('#back-to-top-btn');

	$(window).on('load', function () {
		if ($(this).scrollTop() > 100) {
			$backToTopButton.fadeIn();
		} else {
			$backToTopButton.fadeOut();
		}
	});

	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 100) {
			$backToTopButton.fadeIn();
		} else {
			$backToTopButton.fadeOut();
		}
	});

	$backToTopButton.on('click', function () {
		$('html, body').animate({ scrollTop: 0 }, 200);
	});

	$('a:not(#external-link-modal--link)').click(function (event) {
    let clickedHref = $(this).attr('href');

    // List of URLs to exclude
    let excludedUrls = [
        'https://www.smarter-traveller.co.uk',
        'https://www.sanofi.co.uk/en/privacy-policy',
        'https://www.sanofi.co.uk/en/contact',
        'https://www.sanofi.co.uk'
    ];

    // Check if the href starts with 'http', 'https', or '//'
    if (clickedHref.startsWith('http') || clickedHref.startsWith('//')) {
        // Check if the clickedHref starts with any of the excluded URLs
        let isExcluded = excludedUrls.some(url => clickedHref.startsWith(url));
        
        if (!isExcluded) {
            event.preventDefault(); // Prevent the link from being followed
            openLinkModal(clickedHref);
        }
    }
});

	$('.external-link-modal--closer').click(function () {
		closeLinkModal();
	});

	$('#external-link-modal').click(function (event) {
		let clickTarget = $(event.target);
		if (clickTarget.is('#external-link-modal')) {
			closeLinkModal();
		}
	});

	function openLinkModal(href) {
		$('#external-link-modal--link').attr('href', href);
		$('#external-link-modal').fadeIn();
		$('body').toggleClass('overflow-hidden');
	}

	function closeLinkModal() {
		$('#external-link-modal').fadeOut();
		$('body').toggleClass('overflow-hidden');
	}
});
